<template>
  <b-card class="bcard">
    <div class="widget-container flex-box">
      <span>Compony Logo</span>
      <div class="img">
        <img
          v-if="currentLogo"
          :src="'data:image/png;base64,' + currentLogo"
          fluid
        />
        <div class="col" v-if="readOnly">
          <div class="row">
            <input type="file" id="file" ref="file" />
          </div>
          <div class="row">
            <label for="">Company Name</label>
            <input
              type="text"
              class="form-control"
              id="companyName"
              ref="companyName"
              v-model="companyName"
            />
          </div>
          <div class="row" v-if="readOnly">
            <DxButton
              :width="120"
              text="Upload"
              type="success"
              styling-mode="contained"
              @click="uploadFn()"
            />
            <DxButton
              :width="120"
              text="Reset"
              type="success"
              styling-mode="contained"
              @click="resetLogo()"
              style="margin-left: 10px"
            />
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>
<script>
import { BCard } from 'bootstrap-vue';
import DxButton from 'devextreme-vue/button';
import axios from '@axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { blobToBase64ForLogo, readOnly } from '@/auth/utils';

export default {
  components: {
    BCard,
    DxButton
  },
  data() {
    return {
      readOnly: true,
      isDropZoneActive: false,
      currentLogo: '',
      imageSource: '',
      textVisible: true,
      progressVisible: false,
      progressValue: 0,
      allowedFileExtensions: ['.jpg', '.jpeg', '.gif', '.png'],
      companyName: ''
    };
  },
  async mounted() {
    await this.getLogo();
    this.readOnly = readOnly();
  },
  methods: {
    showToast({ variant = 'success', title, text, icon = 'XIcon' }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    },
    async getLogo() {
      let result = await axios.get('/company-logo');
      if (result.status == 200) {
        console.log(result.data);
        let image = blobToBase64ForLogo(result.data.image.data);
        this.currentLogo = image;
        let q = localStorage.getItem('userInfo');
        if (q) {
          let r = JSON.parse(q);
          if (r) {
            r.companyName = result.data.company_name;
            this.companyName = r.companyName;
            localStorage.setItem('userInfo', JSON.stringify(r));
          }
        }
      }
    },
    async resetLogo() {
      let result = await axios.get('/company-logo-reset');

      if (result.status == 200) {
        localStorage.setItem('userInfo', '');
        location.reload();
      }
    },
    async uploadFn() {
      this.file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('company_name', this.companyName);

      let result = await axios.post('/company-logo-upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (result.status == 200) {
        this.showToast({
          variant: 'success',
          text: 'Logo yüklendi..'
        });
        await this.getLogo();
        location.reload();
      } else {
        this.showToast({
          variant: 'error',
          text: 'Logo yüklenemedi..'
        });
      }
    },
    onDropZoneEnter(e) {
      if (e.dropZoneElement.id === 'dropzone-external') {
        this.isDropZoneActive = true;
      }
    },
    onDropZoneLeave(e) {
      if (e.dropZoneElement.id === 'dropzone-external') {
        this.isDropZoneActive = false;
      }
    },
    onUploaded(e) {
      const { file } = e;
      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.isDropZoneActive = false;
        this.imageSource = fileReader.result;
      };
      fileReader.readAsDataURL(file);
      this.textVisible = false;
      this.progressVisible = false;
      this.progressValue = 0;
    },
    onProgress(e) {
      this.progressValue = (e.bytesLoaded / e.bytesTotal) * 100;
    },
    onUploadStarted() {
      this.imageSource = '';
      this.progressVisible = true;
    }
  }
};
</script>
<style>
.img img {
  width: 325px;
  height: 325px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  padding: 10px;
  flex-wrap: wrap;
  object-fit: contain;
}

#dropzone-external > * {
  pointer-events: none;
}

.widget-container > span {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
}

#dropzone-text > span {
  font-weight: 100;
  opacity: 0.5;
}

.col row {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input {
  margin: 20px 0px;
  font-size: 14px;
}
</style>
